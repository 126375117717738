import styled from 'styled-components';
import { breakpoints } from '@checkingin/component-library';

export const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.span`
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: hsl(346, 86%, 55%);
  text-align: center;
`;

export const Object = styled.object.attrs({
  id: 'object-wbs-report',
  title: 'object-wbs-report',
  frameBorder: 0,
  width: '100%'
})`
  height: 65vh;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 75vh;
  }
`;

export const Text = styled.span``;

export const LinkText = styled.a``;
