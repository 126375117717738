import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '@checkingin/component-library';

import { avatarLinks, menuLinks, pages } from './pages';
import { useUserData } from './contexts/UserDataContext';

const App = () => {
  const { t } = useTranslation();

  const [pageParams, setPageParams] = useState({});
  const { logout } = useAuth0();
  const { userData } = useUserData();

  const { push } = useHistory();

  const changePageMiddleware = ({ params }) => {
    setPageParams(params);
  };

  // go to named inline step;
  const goTo = (pageKey, params = {}) => {
    changePageMiddleware({ params });
    // if (pageKey in pages) {
    //   setActivePageKey(pageKey);
    //   setCurrentPage(pages[pageKey]);
    // } else {
    //   setActivePageKey('404');
    //   setCurrentPage(pages['404']);
    // }

    push(`/${pageKey}`);
  };

  // clear store and go back to home step (0);
  const goHome = (params = {}) => {
    changePageMiddleware({ params });
    // setActivePageKey('checkin');
    // setCurrentPage(pages.checkin);
    push('/');
  };

  const rootPage = pages.find((page) => page.isRoot);
  const RootComponent = rootPage?.page_component;

  return (
    <PageWrapper
      data-testid="page-wrapper"
      goHome={goHome}
      goTo={(pageKey) => push(`/${pageKey}`)}
      logout={logout}
      user={userData || {}}
      avatarLinks={avatarLinks(push)}
      navLinks={menuLinks
        .filter((link) =>
          link.uid === 'group_insights' && userData.hideGroupInsightsMenu
            ? false
            : link
        )
        .filter((link) => (userData.isEnterprise ? link : !link.isEnterprise))
        .filter((link) => (userData.isCompanyAdmin ? link : !link.isAdmin))
        .map((link) => {
          const initialLink = {
            ...link,
            title: t(link.title)
          };

          if (link.links) {
            initialLink.links = link.links.map((innerLink) => ({
              ...innerLink,
              title: t(innerLink.title)
            }));
          }

          return initialLink;
        })}
    >
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            if (RootComponent) {
              return (
                <RootComponent
                  goTo={goTo}
                  goHome={goHome}
                  pageParams={pageParams}
                />
              );
            }
            window.location.href = rootPage.external_link;
            return null;
          }}
          key="root-route"
        />
        {pages.map((page) => (
          <Route
            exact={page?.exact}
            path={`/${page.page_key}`}
            component={() => {
              if (page.page_component) {
                const PageComponent = page.page_component;
                return (
                  <PageComponent
                    goTo={goTo}
                    goHome={goHome}
                    pageParams={pageParams}
                    pageKey={page.page_key}
                  />
                );
              }
              window.location.href = page.external_link;
              return null;
            }}
            key={page.page_key}
          />
        ))}
        <Route
          path="/logout"
          render={() => {
            logout();
            return <div />;
          }}
          key="logout"
        />
        <Route
          path="*"
          component={
            pages.find((page) => page.page_key === '404').page_component
          }
          key="404"
        />
      </Switch>
    </PageWrapper>
  );
};

export default App;
