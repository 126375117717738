import { lazy } from 'react';
import {
  faChartBar,
  faCog,
  faPlus,
  faHistory,
  faHome
} from '@fortawesome/free-solid-svg-icons';

import { _404_ } from './_404_';
import { Checkin } from './Checkin';
import { History } from './History';
import Trends from './Trends';
import Insights from './Insights';
import GroupInsights from './GroupInsights';
import Challenges from './Challenges';
import { Profile } from './Profile';
import { Home } from './Home';

import { Exercise } from './Exercise';
import { Program } from './Program';
import { Mindfulness } from './Mindfulness';
import { ExerciseContent } from './Exercise/ExerciseContent';
import { ExerciseEvaluation } from './Exercise/ExerciseEvaluation';

const Baseline = lazy(() => import('./wellbeing-survey/baseline'));
const Exit = lazy(() => import('./wellbeing-survey/exit'));

const adminURL = process.env.REACT_APP_WEB_ADMIN_URL;

export const pages = [
  {
    menu_name: false,
    custom_header: false,
    page_component: _404_,
    custom_footer: false,
    page_key: '404'
  },
  {
    menu_name: 'Home',
    custom_header: false,
    page_component: Home,
    custom_footer: false,
    page_key: 'home',
    isRoot: true
  },
  {
    menu_name: 'Mindfulness',
    custom_header: false,
    page_component: Mindfulness,
    custom_footer: false,
    page_key: 'mindfulness'
  },
  {
    menu_name: 'Program',
    custom_header: false,
    page_component: Program,
    custom_footer: false,
    page_key: 'program/:programId'
  },
  {
    menu_name: 'Exercise',
    custom_header: false,
    page_component: Exercise,
    custom_footer: false,
    page_key: 'exercise/:exerciseId',
    exact: true
  },
  {
    menu_name: 'Exercise Evaluation',
    custom_header: false,
    page_component: ExerciseEvaluation,
    custom_footer: false,
    page_key: 'exercise/:exerciseId/evaluation',
    exact: true
  },
  {
    menu_name: 'ExerciseContent',
    custom_header: false,
    page_component: ExerciseContent,
    custom_footer: false,
    page_key: 'exercise/:exerciseId/content',
    exact: true
  },
  {
    menu_name: 'Check In',
    custom_header: false,
    page_component: Checkin,
    custom_footer: false,
    page_key: 'checkin'
  },
  {
    menu_name: 'My History',
    custom_header: false,
    page_component: History,
    custom_footer: false,
    page_key: 'history'
  },
  {
    menu_name: 'Insights',
    custom_header: false,
    page_component: Insights,
    custom_footer: false,
    page_key: 'insights'
  },
  {
    menu_name: 'Group Insights',
    custom_header: false,
    page_component: GroupInsights,
    custom_footer: false,
    page_key: 'group_insights'
  },
  {
    menu_name: 'Trends',
    custom_header: false,
    page_component: Trends,
    custom_footer: false,
    page_key: 'trends'
  },
  {
    menu_name: 'Well-Being',
    custom_header: false,
    page_component: Challenges,
    custom_footer: false,
    page_key: 'challenges'
  },
  {
    menu_name: 'Group Settings',
    custom_header: false,
    // page_component: Challenges,
    external_link: `${adminURL}/group_settings`,
    custom_footer: false,
    page_key: 'group_settings'
  },
  {
    menu_name: 'About',
    custom_header: false,
    // page_component: Challenges,
    external_link: `${adminURL}/subgroups`,
    custom_footer: false,
    page_key: 'subgroups'
  },
  {
    menu_name: 'Members',
    custom_header: false,
    // page_component: Challenges,
    external_link: `${adminURL}/members`,
    custom_footer: false,
    page_key: 'members'
  },
  {
    custom_header: false,
    page_component: Profile,
    custom_footer: false,
    page_key: 'profile'
  },
  {
    menu_name: 'Wellbeing - Baseline',
    custom_header: false,
    page_component: Baseline,
    custom_footer: false,
    page_key: 'wellbeing/:userId',
    exact: true
  },
  {
    menu_name: 'Wellbeing - Exit',
    custom_header: false,
    page_component: Exit,
    custom_footer: false,
    page_key: 'wellbeing/exit/:userId',
    exact: true
  }
];

export const menuLinks = [
  {
    isAdmin: false,
    isEnterprise: false,
    uid: 'home',
    title: 'Home',
    path: 'home',
    icon: faHome
  },
  {
    isAdmin: false,
    isEnterprise: false,
    uid: 'checkin',
    title: 'Add Check in',
    path: 'checkin',
    icon: faPlus
  },
  {
    isAdmin: false,
    isEnterprise: false,
    uid: 'history',
    title: 'My History',
    path: 'history',
    icon: faHistory
  },
  {
    isAdmin: false,
    isEnterprise: false,
    uid: 'my_insights',
    title: 'My Insights',
    path: 'insights',
    icon: faChartBar
  },
  // {
  //   isEnterprise: true,
  //   uid: 'challenges',
  //   title: 'Well-Being',
  //   path: 'challenges',
  //   icon: faChartBar
  // },
  {
    isAdmin: true,
    isEnterprise: true,
    uid: 'group_management',
    title: 'Group Management',
    path: null,
    icon: faCog,
    links: [
      {
        uid: 'group_settings',
        title: 'About',
        path: 'group_settings'
      },
      {
        uid: 'members',
        title: 'Members',
        path: 'members'
      },
      {
        uid: 'subgroups',
        title: 'Subgroups',
        path: 'subgroups'
      },
      {
        uid: 'group_insights',
        title: 'Group Insights',
        path: 'group_insights'
      }
    ]
  }
  // {
  //   uid: 'groupPermissions',
  //   title: 'Permissions',
  //   path: 'groupPermissions',
  //   icon: faUsers
  // }
];

export const avatarLinks = (historyPush) => [
  {
    icon: 'https://bucket-programs-test.s3.us-east-2.amazonaws.com/media/image/d92f1fc6861c0c6939e97765ef988bb7.user-alt-solid.svg',
    alt: 'profile_icon',
    title: 'My Profile',
    action: () => {
      historyPush('/profile');
    }
  }
];
