import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useContent } from '../../contexts/ContentContext';

import CardHeader from '../../components/CardHeader';
import ExerciseItemList from '../../components/ExerciseItemList';

import { FailContainer, FailText } from '../Exercise/styles';
import { ContentList, ContainerCard } from './styles';

const Mindfulness = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { programs } = useContent();

  const mindfulnessPrograms = useMemo(
    () =>
      programs.filter(
        (item) =>
          item.visibleOnly.includes('mindfulness') ||
          item.visibleOnly.length === 0
      ),
    [programs]
  );

  if (!mindfulnessPrograms || mindfulnessPrograms.length === 0) {
    return (
      <FailContainer>
        <FailText>{t('messages.Fail to load this program')}</FailText>
      </FailContainer>
    );
  }

  return (
    <ContainerCard>
      <CardHeader title={t('Mindfulness')} goPrevious={() => push('/home')} />
      <ContentList>
        {mindfulnessPrograms.map((mindfulness, mindfulnessIndex) => (
          <ExerciseItemList
            key={`MindfulnessProgramItem-${mindfulnessIndex}`}
            item={mindfulness}
            shouldHideNewsWithoutCount
            onPress={() =>
              push(
                `/program/${mindfulness.id}?publicationId=${mindfulness.publicationId}`
              )
            }
          />
        ))}
      </ContentList>
    </ContainerCard>
  );
};

export { Mindfulness };
