import {
  COLORS as CheckingInColors,
  IMAGES as CheckingInImages
} from './CheckingIn';
import { COLORS as SquamishColors, IMAGES as SquamishImages } from './Squamish';
import { COLORS as SqewletsColors, IMAGES as SqewletsImages } from './Sqewlets';
import { COLORS as TlaaminColors, IMAGES as TlaaminImages } from './Tlaamin';

import { COMMON_THEME_VALUES } from './Common';

const THEME = {
  checkingin: {
    colors: CheckingInColors,
    images: CheckingInImages,
    ...COMMON_THEME_VALUES
  },
  squamish: {
    colors: SquamishColors,
    images: SquamishImages,
    ...COMMON_THEME_VALUES
  },
  sqewlets: {
    colors: SqewletsColors,
    images: SqewletsImages,
    ...COMMON_THEME_VALUES
  },
  tlaamin: {
    colors: TlaaminColors,
    images: TlaaminImages,
    ...COMMON_THEME_VALUES
  }
};

export default THEME;
