import styled from 'styled-components';
import { TextField as InnerTextField } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;

  padding: 0rem 1.25rem;
  width: 100%;
  gap: 3rem;
`;

export const TitleContainer = styled.div``;

export const TitleText = styled.span`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.193rem;
  color: hsl(120, 1%, 39%);
  text-align: center;
  font-family: Lato, sans-serif;
`;

export const DescriptionContainer = styled.div``;

export const DescriptionText = styled.span`
  font-family: Lato, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
`;

export const TextField = styled(InnerTextField)`
  width: 100%;

  .MuiFormHelperText-root {
    text-align: right !important;
    margin-right: 0 !important;
  }
`;
