import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  font-family: Lato, sans-serif;

  color: #636463;
`;

export const IFrame = styled.iframe.attrs({
  id: 'iframe-exercise-file-id',
  title: 'iframe-exercise-file-title',
  frameBorder: 0
})`
  height: 37.5rem;
  width: 100%;
`;
