import moment from 'moment';

export function sortByField(array, field) {
  return array.sort((a, b) => {
    const fa = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
    const fb = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];

    if (fa === fb) return 0;
    return fa < fb ? -1 : 1;
  });
}

export function removeDuplicates(arr) {
  const set = [...new Set(arr.map((item) => JSON.stringify(item)))];
  const uniqueValuesArray = set.map((s) => JSON.parse(s));
  return uniqueValuesArray;
}

export function getUserFeaturedExercise(exercises) {
  const today = moment();
  const currentDay = today.dayOfYear();
  const daysInYear = today.endOf('year').dayOfYear();

  const featuredIndex = Math.floor(
    ((exercises.length - 1) / daysInYear) * currentDay
  );

  return exercises[featuredIndex];
}

// export const isProgramLocked = (program, userHistory = []) => {
//   let isLockedByPreviousContent = false;

//   if (program.completedDate) {
//     return isLockedByPreviousContent;
//   }

//   if (program?.availableAfterIds && program.availableAfterIds.length > 0) {
//     if (!userHistory || userHistory.length === 0) {
//       isLockedByPreviousContent = true;
//     }

//     isLockedByPreviousContent = !program.availableAfterIds.every(
//       (afterExerciseId) =>
//         userHistory.some(
//           (historic) =>
//             historic.id === afterExerciseId && historic.completedDate
//         )
//     );
//   }

//   const isLockedByDate = !program.availableDate
//     ? false
//     : moment(program.availableDate.substring(0, 16))
//         .local()
//         .isAfter(moment().local().format());

//   return isLockedByDate || isLockedByPreviousContent;
// };
export const isProgramLocked = (status) => status === 'upcoming';

export const countNewExercises = (flags) =>
  flags?.filter((flag) => flag.flag === 'new').length;
