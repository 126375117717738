import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { PropTypes } from 'prop-types';
import { useQuery } from '@apollo/client';

import { fetchContentJSON } from '../services/fetchContentJSON';
import { useUserData } from './UserDataContext';
import { removeDuplicates } from '../helpers/programs';
import LoadingSpinner from '../components/LoadingSpinner';
import userGraph from '../services/graphql-queries/user';

const ContentContext = createContext({});

function useContent() {
  return useContext(ContentContext);
}

function ContentProvider({ children }) {
  const { userData } = useUserData();

  const {
    loading,
    data: userContentHistory,
    refetch: refetchContent
  } = useQuery(userGraph.GET_USER_PUBLICATIONS, {
    fetchPolicy: 'network-only',
    variables: { params: { version: 'web' } }
  });

  const [programs, setPrograms] = useState([]);
  const [exercises, setExercises] = useState([]);

  const [contentHistories, setContentHistory] = useState([]);

  const addContentHistories = useCallback(
    async ({ exerciseContentHistories = [], reloadFlags = false }) => {
      setContentHistory([
        ...contentHistories.filter(
          (item) =>
            !exerciseContentHistories.some(
              (o) =>
                o.cmsId === item.cmsId &&
                o.publicationRecordId === item.publicationRecordId
            )
        ),
        ...exerciseContentHistories
      ]);

      if (reloadFlags) {
        await refetchContent();
      }
    },
    [contentHistories]
  );

  const fetchPublications = async () => {
    if (!userContentHistory?.user?.publications) return;
    // const selectedLanguage = language || i18n.language;
    const publicationsAvailable = userContentHistory?.user.publications;

    const publicationsLoaded = await Promise.all(
      publicationsAvailable.map(async (publication) => {
        try {
          const publicationData = await fetchContentJSON(
            publication.objectName
          );

          return publicationData.map((exercise) => {
            const exerciseFlags =
              userContentHistory?.user?.publications
                .find(
                  (userPublication) => userPublication.id === publication.id
                )
                ?.flags?.find((flag) => flag.originId === exercise.id) || [];

            return {
              ...exercise,
              categories: [exercise.categories[0]],
              publicationId: publication.id,
              flags: exerciseFlags,
              flag: exerciseFlags?.flag || 'upcoming'
            };
          });
        } catch (err) {
          console.log('Failed to fetch JSON', publication.objectName, err);
          return [];
        }
      })
    );

    const exercisesLoaded = removeDuplicates(publicationsLoaded.flat());

    const programsLoaded = exercisesLoaded.reduce((acc, currentExercise) => {
      const { categories, publicationId, flags } = currentExercise;

      // eslint-disable-next-line no-param-reassign
      delete currentExercise.flags;

      categories.forEach((category) => {
        const index = acc.findIndex(
          (item) =>
            item.id === category.id && item.publicationId === publicationId
        );

        if (index === -1) {
          acc.push({
            ...category,
            publicationId,
            exercises: [
              {
                ...currentExercise
              }
            ],
            flags: [flags]
          });
        } else {
          acc[index].exercises.push({
            ...currentExercise
          });
          acc[index].flags.push(flags);
        }
      });

      return acc;
    }, []);

    setPrograms(programsLoaded);
    setExercises(exercisesLoaded);
  };

  useEffect(() => {
    if (userData && userContentHistory && !loading) {
      fetchPublications();
    }
  }, [userData, userContentHistory]);

  return (
    <ContentContext.Provider
      value={{
        loading,
        refetchContent,
        refetchPublications: fetchPublications,
        programs,
        exercises,
        contentHistories,
        addContentHistories
      }}
    >
      {loading ? <LoadingSpinner wrapper /> : children}
    </ContentContext.Provider>
  );
}

ContentProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export { ContentProvider, useContent };
