import styled, { css } from 'styled-components';
import { Box as InnerBox } from '@mui/material';
import { breakpoints, colors } from '@checkingin/component-library';

import { FileIcon as InnerFileIcon } from './FileIcon';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  border: 1px solid ${colors.primary};
  border-radius: 0.25rem;
`;

export const Box = styled(InnerBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: ${colors.white.absolute};
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 2rem;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 50%;
  }
`;

export const PreviewImage = styled.img.attrs({
  alt: 'Preview Image'
})`
  width: 100%;
  max-width: 20rem;
  border-radius: 0.5rem;
`;

export const TopContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.primary};
  padding: 1rem;
  flex-direction: column;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 2rem;
    flex-direction: row;
  }
`;

export const TopLeftContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopRightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  margin: ${({ hasImage }) =>
    hasImage ? '1.5rem 3rem 1.5rem 1.5rem' : '1.5rem 3rem 1.5rem 0rem'};

  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${breakpoints.tablet}px) {
    align-items: ${({ hasImage }) => (hasImage ? 'center' : 'flex-start')};

    margin: 1rem 0rem 1rem 0rem;
  }
`;

export const TextType = styled.span`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1rem;
  color: #636463;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.063rem;
    line-height: 1.25rem;
  }
`;

export const Text = styled.span`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.938rem;
  line-height: 1.125rem;
  color: #636463;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
`;

export const FileIcon = styled(InnerFileIcon)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0rem;
  cursor: pointer;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-right: 1px solid ${colors.primary};
    `}

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 1.375rem 0rem;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ isUnique }) => (isUnique ? 'space-around' : 'center')};
  align-items: center;
`;
