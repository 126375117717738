import styled from 'styled-components';
import { Tabs as InnerTabs, Tab as InnerTab } from '@mui/material';
import {
  colors,
  breakpoints,
  spacing,
  Button as InnerButton
} from '@checkingin/component-library';

const EXERCISE_CARD_PADDING = 2;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  background-color: ${colors.white.absolute};
  padding-top: 0.938rem;
  margin: 0.625rem 0.625rem 8rem 0.625rem;
  width: 100%;
  height: fit-content;

  @media (min-width: ${breakpoints.tablet}px) {
    width: max-content;
    min-width: 50%;
    max-width: 50%;
  }
`;

export const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: fit-content;
`;

export const ContentImg = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  border: none;
  border-radius: 0.625rem;
  width: 90%;
  height: 8rem;

  opacity: ${({ isLocked }) => (isLocked ? 0.5 : 1)};

  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px -50px 50px 25px rgba(0, 0, 0, 0.7);

  @media (min-width: ${breakpoints.tablet}px) {
    width: 50%;
    padding: 0 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef7f6;
`;

export const Button = styled(InnerButton)`
  border-radius: 1.5rem !important;
  width: 80%;
  text-align: center;
  background-color: ${colors.primary};
  color: ${colors.white.absolute};
  padding: ${spacing.xs} ${spacing.xl};
  cursor: pointer;
  font-family: Lato, sans-serif !important;
  margin: 0 auto;

  @media (min-width: ${breakpoints.tablet}px) {
    width: calc(50% + ${EXERCISE_CARD_PADDING}rem);
  }
`;

export const SubTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid gray;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Tabs = styled(InnerTabs)`
  .MuiTab-textColorPrimary {
    font-style: normal;
    font-weight: 400;
    font-size: 1.063rem;
    line-height: 1.25rem;
    text-align: center;
    text-transform: none;
    color: #636463 !important;
    background-color: #eef7f6;
  }

  .Mui-selected {
    font-weight: 700;
  }

  .MuiTabs-indicator {
    background-color: ${colors.primary};
  }
`;

export const Tab = styled(InnerTab)``;

export const FailContainer = styled(ContainerCard)`
  justify-content: center;
  align-items: center;
`;

export const FailText = styled.span`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  color: #636463;
`;

export const ExerciseTitleWrapper = styled.div`
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExerciseTitle = styled.span`
  color: ${colors.white.absolute};
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: bold;
  text-align: center;
`;
